<template>
  <div class="finance-voucher-page">
    <div class="finance-voucher-content">
      <div class="voucher-header-upload">
        <el-button type="primary" icon="el-icon-bottom" @click="downFiles"
          >打包下载</el-button
        >
        <el-button type="primary" icon="el-icon-bottom" @click="exportFiles"
          >导出表格</el-button
        >
      </div>
      <div style="display: flex; position: relative">
        <div class="content" style="height: 630px; width: 84%">
          <el-table
            :data="tableData"
            max-height="630"
            :header-cell-style="{
              color: '#333333',
              backgroundColor: '#FBFBFD',
            }"
            border
            fit
            @row-click="clickRow"
            ref="multipleTable"
            @selection-change="handleSelectionChange"
          >
            <el-table-column align="center" type="selection" width="55">
            </el-table-column>
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="62"
            >
            </el-table-column>
            <el-table-column
              label="科目"
              prop="AccountName"
              align="center"
              sortable
              min-width="150"
            >
            </el-table-column>
            <el-table-column
              label="金额(元)"
              prop="PayoutMoney"
              align="center"
              sortable
              min-width="150"
            >
            </el-table-column>
            <el-table-column
              label="资金来源"
              prop="Source"
              align="center"
              sortable
              width="120"
            >
              <template slot-scope="scope">
                <span v-show="scope.row.Source == 0">中央财政资金</span>
                <span v-show="scope.row.Source == 1">其它来源资金</span>
              </template>
            </el-table-column>
            <el-table-column
              label="凭证编号"
              prop="FinancCode"
              align="center"
              sortable
              width="150"
            >
            </el-table-column>
            <el-table-column
              label="发生日期"
              prop="OccurrenceDate"
              align="center"
              sortable
              :formatter="formatter"
              min-width="150"
            >
              <!--              <template slot-scope="scope">-->
              <!--                <span>{{scope.row.OccurrenceDate.substring(0,10)}}</span>-->
              <!--              </template>-->
            </el-table-column>
            <el-table-column
              prop="SerialNumber"
              label="课题/子课题序号"
              align="center"
              sortable
              min-width="170"
            >
            </el-table-column>
            <el-table-column
              prop="HospitalName"
              label="录入单位"
              align="center"
              sortable
              min-width="200"
            >
            </el-table-column>
            <el-table-column
              prop="UserName"
              label="录入人"
              align="center"
              sortable
              width="150"
            >
            </el-table-column>
            <el-table-column label="下载附件" align="center" width="120">
              <template slot-scope="scope">
                <span
                  @click.stop="seeFile(scope.row)"
                  style="color: #3978e7; font-weight: 400"
                  class="p-hover"
                  >附件</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="160">
              <template slot-scope="scope">
                <el-link
                  style="
                    font-size: 16px;
                    color: #3978e7;
                    font-weight: 400;
                    margin-right: 11px;
                  "
                  type="text"
                  :underline="false"
                  @click.stop="edit(scope.row)"
                  ><i class="el-icon-edit-outline"></i>编辑</el-link
                >
                <el-link
                  style="
                    font-size: 16px;
                    color: #3978e7;
                    font-weight: 400;
                    margin-right: 11px;
                  "
                  type="text"
                  :underline="false"
                  @click.stop="delFinance(scope.row)"
                  ><i class="el-icon-delete"></i>删除</el-link
                >
                <!--                <el-link-->
                <!--                  style="font-size:16px;color: #3978E7"-->
                <!--                  type="text"-->
                <!--                  :underline="false"-->
                <!--                  @click.stop="imgItem(scope.row)"-->
                <!--                ><img src="../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;vertical-align: -3px">查看</el-link>-->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="img-content">
          <div
            style="
              width: 260px;
              height: 630px;
              overflow-y: auto;
              display: flex;
              flex-wrap: wrap;
              align-content: flex-start;
            "
            @scroll="Scroll"
            v-if="!loadDataGuid"
          >
            <div
              v-show="this.imgLists.length == 0"
              style="
                width: 200px;
                height: 100px;
                text-align: center;
                color: #666;
                line-height: 70px;
              "
            >
              暂无凭证附件
            </div>
            <div
              v-for="(item, indexs) in imgLists"
              :key="indexs"
              style="
                width: 120px;
                height: 70px;
                margin-right: 4px;
                margin-bottom: 20px;
                position: relative;
              "
              @click.stop="preimgList(item)"
              @mouseenter="mouseOver(item.Id)"
              @mouseleave="mouseLeave"
            >
              <a
                :href="api2 + item.Url"
                download
                target="_blank"
                v-show="
                  item.Suffix == 'png' ||
                  item.Suffix == 'jpg' ||
                  item.Suffix == 'jpeg'
                "
              >
                <el-image
                  :src="api2 + item.Url"
                  lazy
                  fit="cover"
                  style="width: 120px; height: 70px"
                />
              </a>
              <a
                :href="api2 + item.Url"
                download
                v-show="item.Suffix == 'pdf'"
                target="_blank"
              >
                <img
                  src="../assets/image/pdf.png"
                  alt=""
                  style="width: 120px; height: 70px"
                />
              </a>
              <a
                :href="api2 + item.Url"
                download
                v-show="item.Suffix == 'xlsx' || item.Suffix == 'xls'"
              >
                <img
                  src="../assets/image/xls.png"
                  alt=""
                  style="width: 120px; height: 70px"
                />
              </a>
              <a
                :href="api2 + item.Url"
                download
                v-show="item.Suffix == 'docx' || item.Suffix == 'doc'"
              >
                <img
                  src="../assets/image/word.png"
                  alt=""
                  style="width: 120px; height: 70px"
                />
              </a>
              <a
                :href="api2 + item.Url"
                download
                v-show="
                  item.Suffix == 'mp4' ||
                  item.Suffix == 'mp3' ||
                  item.Suffix == 'rar' ||
                  item.Suffix == 'ppt' ||
                  item.Suffix == 'txt' ||
                  item.Suffix == 'zip'
                "
              >
                <img
                  src="../assets/image/mor.png"
                  alt=""
                  style="width: 120px; height: 70px"
                />
              </a>
              <span class="ellip-span">{{ item.FileName }}</span>
              <div
                style="
                  width: 120px;
                  height: 70px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 10;
                  border-radius: 6px;
                "
                v-show="item.Id == num"
                class="changColor"
              ></div>
              <i
                class="el-icon-view p-hover"
                v-show="item.Id == num"
                style="
                  position: absolute;
                  bottom: 4px;
                  left: 12px;
                  color: white;
                  z-index: 100;
                "
              ></i>
              <a
                v-show="item.Id == num"
                
                style="
                  position: absolute;
                  bottom: 2px;
                  left: 80px;
                  color: white;
                  z-index: 100;
                "
                @click.stop="downloadfile(item.FileDataId)"
                class="p-hover"
              >
                <span
                  class="el-icon-download"
                  style="font-size: 16px; margin-left: 10px"
                ></span>
                <!--                <p style="font-size: 14px">下载</p>-->
              </a>
            </div>
          </div>
          <div
            style="
              width: 260px;
              height: 630px;
              overflow-y: auto;
              display: flex;
              flex-wrap: wrap;
              align-content: flex-start;
            "
            v-show="loadDataGuid"
          >
            <div
              v-show="this.imgList.length == 0"
              style="
                width: 200px;
                height: 100px;
                text-align: center;
                color: #666;
                line-height: 70px;
              "
            >
              暂无凭证附件
            </div>
            <div
              v-for="(item, indexs) in imgList"
              :key="indexs"
              style="
                width: 120px;
                height: 70px;
                margin-right: 2px;
                margin-bottom: 20px;
                position: relative;
              "
              @click.stop="pre(item.Id)"
              @mouseenter="mouseOver(item.Id)"
              @mouseleave="mouseLeave"
            >
              <a
                :href="api + item.SysFileName + '.' + item.Suffix"
                download
                target="_blank"
                v-show="
                  item.Suffix == 'png' ||
                  item.Suffix == 'jpg' ||
                  item.Suffix == 'jpeg'
                "
              >
                <el-image
                  :src="api + item.SysFileName + '.' + item.Suffix"
                  lazy
                  fit="cover"
                  style="width: 120px; height: 70px; border-radius: 6px"
                />
              </a>
              <a
                :href="api + item.SysFileName + '.' + item.Suffix"
                download
                v-show="item.Suffix == 'pdf'"
                target="_blank"
              >
                <img
                  src="../assets/image/pdf.png"
                  alt=""
                  style="width: 120px; height: 70px"
                />
              </a>
              <a
                :href="api + item.SysFileName + '.' + item.Suffix"
                download
                v-show="item.Suffix == 'xlsx' || item.Suffix == 'xls'"
              >
                <img
                  src="../assets/image/xls.png"
                  alt=""
                  style="width: 120px; height: 70px"
                />
              </a>
              <a
                :href="api + item.SysFileName + '.' + item.Suffix"
                download
                v-show="item.Suffix == 'docx' || item.Suffix == 'doc'"
              >
                <img
                  src="../assets/image/word.png"
                  alt=""
                  style="width: 120px; height: 70px"
                />
              </a>
              <a
                :href="api + item.SysFileName + '.' + item.Suffix"
                download
                v-show="
                  item.Suffix == 'mp4' ||
                  item.Suffix == 'mp3' ||
                  item.Suffix == 'rar' ||
                  item.Suffix == 'ppt' ||
                  item.Suffix == 'txt' ||
                  item.Suffix == 'zip'
                "
                target="_blank"
              >
                <img
                  src="../assets/image/mor.png"
                  alt=""
                  style="width: 120px; height: 70px"
                />
              </a>
              <span class="ellip-span">{{ item.FileName }}</span>
              <div
                style="
                  width: 120px;
                  height: 70px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 10;
                  border-radius: 6px;
                "
                v-show="item.Id == num"
                class="changColor"
              ></div>
              <i
                class="el-icon-view p-hover"
                v-show="item.Id == num"
                style="
                  position: absolute;
                  bottom: 4px;
                  left: 12px;
                  color: white;
                  z-index: 100;
                "
              ></i>
              <a
                v-show="item.Id == num"
                @click.stop="downloadfile(item.Id)"
                style="
                  position: absolute;
                  bottom: 2px;
                  left: 80px;
                  color: white;
                  z-index: 100;
                "
                class="p-hover"
              >
                <span
                  class="el-icon-download"
                  style="font-size: 16px; margin-left: 10px"
                ></span>
                <!--                <p style="font-size: 14px">下载</p>-->
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="eachpage"
          layout="total,prev, pager, next, jumper"
          :total="PageCount"
        >
        </el-pagination>
        <div class="total_num">
          合计:<span v-show="total_num != 0">{{ total_num }}元</span
          ><span class="span1" v-show="total_num == 0"
            >(请先勾选需要计算的条目)</span
          >
        </div>
      </div>
      <div class="btn">
        <button @click="fn">关闭</button>
      </div>
    </div>
    <div class="voucher-upload">
      <el-dialog :visible.sync="dialogEdit" top="4vh">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">编辑</span>
        </div>
        <el-form ref="form" label-width="100px">
          <el-form-item label="录入单位:">
            <el-autocomplete
              class="inline-input"
              v-model="editHospitalName"
              value-key="HospitalName"
              :fetch-suggestions="querySearch"
              placeholder="请输入医院选择"
              :trigger-on-focus="false"
              @select="handleSelect"
              disabled
              style="width: 100%"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="项目编号:" v-if="TopicClass == 0">
            <el-input v-model="editTopicCode" disabled></el-input>
          </el-form-item>
          <el-form-item label="课题序号:" v-else>
            <el-input v-model="editSerialNumber" disabled></el-input>
          </el-form-item>
          <el-form-item label="科目名称:">
            <el-select
              v-model="editAccountName"
              clearable
              placeholder="请选择科目名称"
              @change="editcurrentSelect"
              style="width: 250px"
            >
              <el-option
                v-for="item in AccountList"
                :key="item.Id"
                :label="item.AccountName"
                :value="item.Id"
              >
              </el-option>
            </el-select>
            <!--            <el-input v-model="editForm.Account"></el-input>-->
          </el-form-item>
          <el-form-item label="资金来源:">
            <el-radio-group v-model="editForm.Source">
              <el-radio :label="0">中央财政资金</el-radio>
              <el-radio :label="1">其它来源资金</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="凭证编号:">
            <el-input v-model="editForm.FinancCode"></el-input>
          </el-form-item>
          <el-form-item label="发生金额:">
            <el-input
              v-model="editForm.PayoutMoney"
              style="width: 90%"
            ></el-input
            >元
          </el-form-item>
          <el-form-item label="发生日期:">
            <el-date-picker
              v-model="editForm.OccurrenceDate"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="摘要说明:">
            <el-input type="textarea" v-model="editForm.Remarks"></el-input>
          </el-form-item>
          <el-form-item label="附件:">
            <div style="display: flex; flex-wrap: wrap">
              <div
                class="del-img"
                v-for="(item, index) in tableDataSee"
                :key="index"
              >
                <p class="del-icon" @click="deletefile(item.Id)">x</p>
                <img src="../assets/image/mor.png" alt="" />
                <el-tooltip placement="top">
                  <div slot="content">
                    {{ item.FileName }}.{{ item.Suffix }}
                  </div>
                  <span>{{ item.FileName }}.{{ item.Suffix }}</span>
                </el-tooltip>
              </div>
              <el-upload
                class="upload-demo"
                ref="newupload"
                accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"
                multiple
                action="#"
                :file-list="editFileList"
                :auto-upload="false"
                :on-change="eidtFileChange"
                :before-remove="editRemove"
              >
                <el-button
                  size="medium"
                  plain
                  icon="el-icon-top"
                  style="
                    color: #4d90f1;
                    border: 1px solid #4d90f1;
                    margin-top: 20px;
                  "
                  >上传文件</el-button
                >
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="录入人:">
            <el-input v-model="editName" disabled></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <button @click="dialogEdit = false">取消</button>
          <button @click="submitEdit">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="FinanceVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFilesDialog"
        :modal-append-to-body="false"
        width="510px"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i
            class="el-icon-warning"
            style="color: #ffba00; font-size: 24px; margin-right: 5px"
          ></i>
          <span style="font-size: 18px; color: #666"
            >此操作将永久删除该选择的文件，是否继续？</span
          >
        </div>
        <div class="btn">
          <button @click="delFilesDialog = false">取消</button>
          <button @click="submitDel">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="FinanceVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFinanceDialog"
        width="510px"
        :modal-append-to-body="false"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i
            class="el-icon-warning"
            style="color: #ffba00; font-size: 24px; margin-right: 5px"
          ></i>
          <span style="font-size: 18px; color: #666"
            >此操作将永久删除该凭证，是否继续？</span
          >
        </div>
        <div class="btn">
          <button @click="delFinanceDialog = false">取消</button>
          <button @click="sumDelFinance">确定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import reseacrh from "../api/research.js";
import { mapState } from "vuex";
import api from "@/api/url";
import { downloadA, downloadFileId, downLoadXls } from "@/utils/downloadUrl";
import { exportTableSelectFinancAccount } from "@/api/fileManagement";
export default {
  data() {
    return {
      exportData:
        "AccountName,PayoutMoney,SourceName,FinancCode,OccurrenceDate,TopicCode,SerialNumber,Remarks,HospitalName,UserName",
      exportDataName:
        "科目名称,发生金额(元),资金来源,凭证编号,发生日期,项目编号,课题/子课题序号,摘要说明,录入单位,录入人",
      editTopicCode: "",
      TopicClass: "",
      Dialog: false,
      dialogEditBody: false,
      changColor: true,
      editImgDataGuid: {}, // 编辑文件的对象
      editSerialNumber: "", // 编辑显示的课题序号
      addSerialNumber: "", // 新增显示的课题序号
      DataGuidList: [], // 下载拼接的数组DataGuid
      loadDataGuid: "", // 右侧图片是否加载问题
      api2: window.global_config.BASE_URL1,
      api: window.global_config.BASE_URL1 + "/TopicFiles/",
      imgList: [],
      AccountList: [], // 科目列表
      UserName: window.sessionStorage.getItem("UserName"),
      HospitalName: "", //医院名称
      fileLists: [], //文件列表
      DataGuid: "", // 编辑的DataGuid
      tableDataSee: [], //编辑查看到的数据列表
      editOption: [],
      delFinanceItem: {}, // 删除凭证的对象
      delFinanceDialog: false, // 删除凭证的弹框
      delFileId: null, // 删除文件的Id
      delFilesDialog: false, // 删除文件的弹框
      dialogEdit: false, // 编辑的弹框
      editAccountName: "",
      editName: "",
      editHospitalName: "",
      editForm: {
        // 编辑对象
        Id: null,
        HospitalId: null,
        TopicId: "",
        Account: null,
        PayoutMoney: null,
        Source: null,
        FinancCode: "",
        Remarks: "",
        UserId: null,
        OccurrenceDate: "", // 发生日期
      },
      Key: "",
      queryHospitalName: "", // 查询医院的名称
      FinanceCode: "", //凭证
      date1: "", // 录入日期
      accountItem: "", // 科目
      SerialNumber: "", // 子课题序号
      options: [],
      fileName: "", // 文件名称
      Remarks: "", // 备注说明
      editFileList: [], // 上传文件列表
      dialogVisible: false, //上传文件弹框
      multipleSelection: [], //多选框数据
      tableData: [],
      ppt: "ppt",
      imglist: [],
      num: -1,
      value: "",
      flag: false,
      TotalPages: 0,
      i: 0, // 项目页数
      imgLists: [], // 项目的所有图片列表
      currentPage: 1,
      PageCount: 0,
      eachpage: 10,
      total_num: 0,
    };
  },
  computed: {
    parame() {
      return JSON.parse(this.$route.query.parame);
    },
  },
  created() {
    this.filelist();
    this.fileInfo();
    // this.projectToPicInfo()
    this.fileImgList();
  },
  methods: {
    formatter(row, column) {
      // 时间排序转换格式
      return row.OccurrenceDate.substring(0, 10);
    },
    downloadimgList(src, name) {
      // 已知url
      downloadA(src, name);
    },
    preimgList(item) {
      console.log('预览执行');
      // 项目进来时图片列表的预览
      // window.open(this.api2 + Url);
      this.downloadfile(item.FileDataId,true);
    },
    fn() {
      window.open("about:blank", "_self").close();
    },
    projectToPicInfo() {
      //
      reseacrh.topic(this.parame.topicId).then((res) => {
        this.TopicClass = res.data.ExtraData.TopicClass;
      });
    },
    Scroll(e) {
      // console.log(this.$refs.evabox.scrollTop)
      const ele = e.srcElement ? e.srcElement : e.target;
      // 监听滚动到div底部
      if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 2) {
        if (this.flag == false) {
          console.log(11);
          this.flag = true;
          this.fileImgList();
        }
      }
    },
    clickRow(item) {
      // 点击行显示右侧图片
      this.imgItem(item);
    },
    imgItem(item) {
      this.loadDataGuid = item.DataGuid;
      reseacrh.preFinancFile(item.DataGuid).then((res) => {
        console.log(res, "查看图片");
        if (res.data.Status == 1) {
          if (res.data.ExtraData.length <= 20) {
            this.imgList = res.data.ExtraData;
          } else {
            this.imgList = res.data.ExtraData.slice(0, 20);
          }
        }
      });
    },
    handleRemove(file, fileList) {
      this.fileLists = fileList;
    },
    handleFileChange(file, fileList) {
      this.fileLists = fileList;
      // console.log(this.fileLists)
    },
    queryList() {
      // 查询
      this.filelist();
    },
    querySelect(value) {
      const obj = this.AccountList.find((el) => el.AccountName === value);
      this.accountItem = obj.AccountName;
    },
    currentSelect(value) {
      const obj = this.AccountList.find((el) => el.Id === value);
      this.addForm.Account = obj.Id;
    },
    eidtFileChange(file, fileList) {
      // 编辑上传文件列表
      this.editFileList = fileList;
      // console.log(this.fileLists)
    },
    editRemove(file, fileList) {
      // 编辑上传取消列表
      this.editFileList = fileList;
      // console.log(this.fileLists)
    },
    editcurrentSelect(value) {
      const obj = this.AccountList.find((el) => el.Id === value);
      this.editForm.Account = obj.Id;
    },
    add() {
      // 上传凭证文件
      this.dialogVisible = true;
    },
    seeFile(item) {
      // 下载附件
      reseacrh.preFinancFile(item.DataGuid).then((res) => {
        if (res.data.Status == 1) {
          res.data.ExtraData.forEach((items) => {
            // if(!/\.(pdf|PDF|png|PNG|jpg|JPG|jpeg|JPEG)$/.test("." +items.Suffix)){
            this.downloadfile(items.Id);
            // }
          });
        }
      });
    },
    submitEdit() {
      // 提交编辑
      let fd = new FormData();
      this.editFileList.forEach((file) => {
        fd.append("file", file.raw);
      });
      console.log(this.editForm, this.editFileList);
      fd.append("data", JSON.stringify(this.editForm));
      reseacrh.editFinanc(fd).then((res) => {
        if (res.data.Status == 1) {
          this.dialogEdit = false;
          this.editFileList = [];
          this.filelist();
          this.i = 0;
          this.TotalPages = 0;
          this.fileImgList();
          reseacrh.preFinancFile(this.editImgDataGuid).then((res) => {
            if (res.data.Status == 1) {
              this.tableDataSee = res.data.ExtraData;
              window.opener.getData(); //调用父页面方法
              // console.log(res,'编辑数据')
            }
          });
          this.$message.success(res.data.Message);
        } else {
          this.$message.error(res.data.Message);
        }
      });
    },
    edit(item) {
      //编辑
      this.editImgDataGuid = item;
      this.DataGuid = item.DataGuid;
      this.dialogEdit = true;
      reseacrh.preFinancFile(item.DataGuid).then((res) => {
        if (res.data.Status == 1) {
          this.tableDataSee = res.data.ExtraData;
          // console.log(res,'编辑数据')
        }
      });
      this.TopicClass = item.ProjectId;
      this.editTopicCode = item.TopicCode;
      this.editSerialNumber = item.SerialNumber;
      this.editForm.Id = item.Id;
      this.editName = item.UserName;
      this.editForm.UserId = item.UserId;
      this.editHospitalName = item.HospitalName;
      this.editForm.HospitalId = item.HospitalId;
      this.editForm.TopicId = item.TopicId;
      this.editForm.Account = item.Account;
      this.editAccountName = item.AccountName;
      this.editForm.Source = item.Source;
      this.editForm.FinancCode = item.FinancCode;
      this.editForm.PayoutMoney = item.PayoutMoney;
      this.editForm.Remarks = item.Remarks;
      this.editForm.OccurrenceDate = item.OccurrenceDate.substring(0, 10);
    },
    handleSelectionChange(val) {
      //多选
      this.multipleSelection = val;
      console.log(val);
      this.total_num = 0;
      val.forEach((item) => {
        this.total_num += item.PayoutMoney;
      });
      this.total_num = this.total_num.toFixed(2);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.filelist();
      this.fileImgListPage(); // 点击分页右侧图片刷新
      // console.log(`当前页: ${val}`);
    },
    fileImgListPage() {
      this.i = 0;
      this.TotalPages = 0;
      this.flag = false;
      this.loadDataGuid = "";
      if (this.i <= this.TotalPages) {
        var fileobj = {
          topicId: this.parame.topicId,
          Id: this.parame.Id,
          field: this.parame.field,
          PageIndex: this.i,
          PageSize: 20,
        };
        api.clickVoucherFileList(fileobj).then((res) => {
          this.imgLists = res.data.Entity;
          this.i++;
          this.flag = false;
          this.TotalPages = res.data.TotalPages - 1;
          // console.log(res,'图片列表分页')
        });
      }
    },
    mouseOver(id) {
      //滑入
      this.changColor = true;
      this.num = id;
    },
    mouseLeave() {
      //滑出
      this.changColor = false;
      this.num = -1;
    },
    pre(id) {
      // reseacrh.preview(id).then(res=>{
      //   console.log(res);
      //   if(res.data.Status==1){
      //     window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
      //     // if(/\.(pdf|PDF|png|PNG|jpg|JPG)$/.test(res.data.ExtraData.Url)){
      //     //   window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
      //     // }else{
      //     //   this.$message.warning("暂时只支持pdf文件预览")
      //       // window.open("https://view.officeapps.live.com/op/view.aspx?src=" + window.global_config.BASE_URL2+res.data.ExtraData.Url);
      //     // }
      //   }
      // })
      this.downloadfile(id, true);
    },
    fileImgList() {
      if (this.i <= this.TotalPages) {
        var fileobj = {
          topicId: this.parame.topicId,
          Id: this.parame.Id,
          field: this.parame.field,
          PageIndex: this.i,
          PageSize: 20,
        };
        api.clickVoucherFileList(fileobj).then((res) => {
          this.imgLists = res.data.Entity;
          this.i++;
          this.flag = false;
          this.TotalPages = res.data.TotalPages - 1;
          // console.log(res,'图片列表分页')
        });
      }
    },
    filelist() {
      var fileobj = {
        topicId: this.parame.topicId,
        Id: this.parame.Id,
        field: this.parame.field,
        PageIndex: this.currentPage - 1,
        PageSize: this.eachpage,
      };
      api.clickVoucherlistData(fileobj).then((res) => {
        if (res.data.Status == 1) {
          this.tableData = res.data.Entity;
          this.PageCount = res.data.TotalRowsCount;
          // console.log(res,'凭证');
        } else {
          this.$message.error(res.data.Message);
        }
      });
    },
    fileInfo() {
      // 文件信息（科目信息）
      reseacrh.FinancInfo().then((res) => {
        this.AccountList = res.data.ExtraData;
        // console.log(res)
      });
    },
    submitDel() {
      // 提交删除单个文件
      reseacrh.alldelet({ Id: this.delFileId }).then((res) => {
        if (res.data.Status == 1) {
          reseacrh.preFinancFile(this.DataGuid).then((res) => {
            if (res.data.Status == 1) {
              this.tableDataSee = res.data.ExtraData;
              this.delFilesDialog = false;
            }
          });
          this.$message.success(res.data.Message);
        } else {
          this.$message.error(res.data.Message);
        }
      });
    },
    sumDelFinance() {
      reseacrh.delFinanc({ id: this.delFinanceItem.Id }).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          this.$message.success(res.data.Message);
          this.delFinanceDialog = false;
          this.filelist();
          window.opener.getData(); //调用父页面方法
        } else {
          this.$message.error(res.data.Message);
        }
      });
    },
    delFinance(item) {
      // 删除凭证弹框
      this.delFinanceDialog = true;
      this.delFinanceItem = item;
    },
    deletefile(id) {
      //删除文件
      this.delFileId = id;
      this.delFilesDialog = true;
    },
    queryHospitalSelect(item) {
      //查询出医院id
      this.queryHospitalName = item.HospitalName;
      // console.log(item,'语音')
    },
    handleSelect(item) {
      //查询出医院id
      this.addForm.HospitalId = item.Id;
      if (this.dialogEdit) {
        this.editForm.HospitalId = item.Id;
      }
    },
    querySearch(queryString, cb) {
      //查询出医院
      console.log(queryString, cb);
      let parame = {
        key: queryString,
      };
      api.hospitalData(parame).then((res) => {
        // console.log(res);
        if (res.data.Status == 1) {
          cb(res.data.Entity);
          // console.log(res,'医院')
        } else {
          this.$message.error(res.data.Message);
        }
      });
    },
    downFiles() {
      // 凭证下载导出图片
      this.DataGuidList = [];
      if (this.multipleSelection.length === 0) {
        this.$message.error("请选择要下载的文件");
        return false;
      }
      if (this.imgLists.length === 0) {
        this.$message.error("打包的凭证文件为空");
        return false;
      }
      this.multipleSelection.forEach((item) => {
        this.DataGuidList.push(item.DataGuid);
      });
      reseacrh
        .DownloadFinancFile({ DataGuids: this.DataGuidList })
        .then((res) => {
          // console.log(res,'拼接图片')
          if (res.status == 200) {
            let data = res.data;
            // let filename = decodeURIComponent(res.headers.filename)
            // downLoadXls(data,filename)
            downLoadXls(data, "财务凭证.zip");
            // this.$message.success('下载成功')
          } else {
            this.$message.error(res.data.Message);
          }
        });
    },
    downloadfile(id,isPre) {
      downloadFileId(id,isPre);
    },
    exportFiles() {
      var exportobj = {
        topicId: this.parame.topicId,
        Id: this.parame.Id,
        field: this.parame.field,
        PageIndex: this.currentPage - 1,
        PageSize: this.eachpage,
        titleName: this.exportDataName,
        titleField: this.exportData,
        ExportFileName: "财务凭证",
      };
      exportTableSelectFinancAccount(exportobj).then((res) => {
        if (res.status == 200) {
          let data = res.data;
          let filename = decodeURIComponent(res.headers.filename);
          downLoadXls(data, filename);
        } else {
          this.$message.error("导出失败");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/mixin.scss";
.finance-voucher-page {
  width: 100%;
  height: 100%;
  overflow: auto;
  .finance-voucher-content {
    width: 100%;
    height: 94%;
    margin-left: 24px;
    //margin-top: 2%;
    margin-right: 24px;
    background: #ffffff;
    padding: 1% 1% 0 1%;
    border-radius: 10px;
    box-sizing: border-box;
    .voucher-header-upload {
      width: 100%;
      margin-bottom: 20px;
      .voucher-header-top {
        margin-bottom: 10px;
        //margin-left: 14px;
      }
      span {
        //display: inline-block;
        color: #69696a;
        @include add-size($font_size_16);
      }
      /deep/.el-select {
        @include add-size($font_size_16);
        width: 180px;
        height: 40px;
        //margin-left: 10px;
        margin-right: 30px;
      }
      /deep/.el-button {
        @include add-size($font_size_16);
      }
      /deep/.el-input {
        width: 180px;
        height: 40px;
        @include add-size($font_size_16);
        margin-right: 30px;
        margin-left: 10px;
      }
    }
  }
}
.voucher-upload {
  /deep/.el-dialog {
    width: 580px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      margin-bottom: 20px;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .el-form {
      padding-right: 20px;
      .el-form-item {
        .del-img {
          //width: 70px;
          width: 120px;
          height: 100px;
          //font-size: 0;
          position: relative;
          margin-right: 6px;
          margin-bottom: 6px;
          .del-icon {
            width: 20px;
            height: 20px;
            position: absolute;
            font-size: 20px;
            top: -6px;
            right: -4px;
            color: white;
            z-index: 99999999;
            background: #666666;
            text-align: center;
            line-height: 20px;
            border-radius: 50%;
          }
          img {
            position: absolute;
            width: 120px;
            height: 70px;
            z-index: 2;
          }
          .tubiao {
            font-size: 70px;
            width: 120px;
          }
          span {
            position: absolute;
            bottom: 0;
            top: 70px;
            display: inline-block;
            font-size: 14px;
            line-height: 18px;
            width: 120px;
            height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .el-form-item__label {
          @include add-size($font_size_16);
          color: #666666;
        }
        .el-input__inner {
          //background: #F4F7F9;
        }
        .el-select {
          width: 100%;
        }
        .el-radio {
          //@include add-size($font_size_16);
        }
        .el-radio__label {
          @include add-size($font_size_16);
        }
        .el-input {
          @include add-size($font_size_16);
          margin-right: 10px;
        }
        .el-textarea__inner {
          @include add-size($font_size_16);
          opacity: 1;
          //background: #F4F7F9;
        }
      }
    }
    .btn {
      margin-top: 52px;
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
.FinanceVoucher-dialog /deep/.el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    border-radius: 10px 10px 0px 0px;
    background: #3388ff;
    margin-bottom: 20px;
    .header-title {
      .title-name {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 4px solid #ffffff;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 4px;
        margin-bottom: 6px;
      }
      .title-age {
        vertical-align: top;
        display: inline-block;
        @include add-size($font_size_16);
        color: #ffffff;
      }
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .btn {
    margin-top: 52px;
    text-align: center;
    button:nth-child(1) {
      width: 180px;
      background: #ffffff;
      border: 1px solid #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #3388ff;
      margin-right: 20px;
    }
    button:nth-child(2) {
      width: 180px;
      //background: #ffffff;
      border: 1px solid #3388ff;
      background: #3388ff;
      height: 50px;
      border-radius: 10px;
      font-size: 24px;
      color: #fff;
    }
  }
}
.img-content {
  position: absolute;
  right: 0;
}
.changColor {
  background: #000000;
  opacity: 0.6;
}
.block {
  margin-top: 20px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.total_num {
  color: #1890ff;
  font-size: 14px;
  margin-left: 10px;
  .span1 {
    color: #4e4e4e;
    font-size: 12px;
  }
}
.content /deep/.el-table th {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.content /deep/.el-table__row {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.content {
  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #1890ff;
    border-radius: 10px;
  }
}
::-webkit-scrollbar {
  width: 8px !important;
  height: 8px;
  background-color: #1890ff;
  border-radius: 10px;
}
.btn {
  width: 100%;
  text-align: center;
  button {
    width: 180px;
    height: 40px;
    border-radius: 10px;
    background: #3388ff;
    font-size: 20px;
    color: #fff;
    border: 0;
  }
}
.p-hover:hover {
  cursor: pointer;
}
.ellip-span {
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
  height: 20px;
  display: block;
  position: absolute;
  bottom: -20px;
  left: 2px;
  color: #606266;
  font-size: 12px;
}
</style>
